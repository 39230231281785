import React from "react";
import AuditPage from "../../components/PageSpeedAudits/AuditPage";
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import BlogCreation from "../../components/BlogAutomationComp/BlogCreation";

const BlogAutomation = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        title={"AI-Driven Blog Creation and Hassle-Free Posting"}
        para={
          "Generate and publish blogs seamlessly."
        }
      />
      <BlogCreation />
      <WebsiteAuditBanner />
      <Footer />
    </>
  );
};

export default BlogAutomation;
