import React, { useState } from "react";
import axios from "axios";

const VideoSearch = () => {
  //const [industry, setIndustry] = useState("");
  const [keywords, setKeywords] = useState("");
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    try {
      const response = await axios.post(
        "https://website-server.fritado.com/api/generate-video",
        {
          //industry,
          keywords,
        }
      );
      console.log(response);
      setVideos(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleReset = () => {
    //setIndustry("");
    setKeywords("");
    setVideos([]);
  };
  return (
    <div className="container mx-auto px-4 head-div ">
      <div className=" ">
        <div className="d-flex flex-column justify-content-center align-items-center text-center ">
          <h2 className="heading">Video Search</h2>
        </div>

      
          <div className="  col-lg-12  d-flex flex-ro flex flex-col lg:flex-row lg:items-center gap-3   rounded video-input-container">
            <div className="d-flex flex-row gap-3" 
        // style={{ maxWidth: "40rem" }}
            >
              <input
                type="email"
                placeholder="Your email"
                className=" w-full lg:w-1/3 flex-1 px-4 py-3 rounded border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all outline-none"
              />
              <input
                type="text"
                placeholder="Keywords"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
            
                className=" w-full   lg:w-3/4 flex-1 px-4 py-3 rounded border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all outline-none"
              />
            </div>
            <div className="d-flex flex-row gap-2">
              <button
                onClick={fetchVideos}
                className="btn-tab mt-0  btn-base-color  lg:flex-none"
              >
                Search Videos
              </button>
              <button
                onClick={handleReset}
                className="lg:flex-none btn-tab  mt-0 bg-gray-200 hover:bg-gray-300 text-gray-700  transition-colors duration-200"
              >
                Reset
              </button>
            </div>
          </div>
       

        {videos.length > 0 && (
          <div className="bg-white rounded  overflow-hidden mt-8">
            <table className="w-full">
              <thead
                className=""
                style={{ backgroundColor: " rgba(47, 130, 162, 0.6509803922)" }}
              >
                <tr>
                  <th className="px-6 py-4 text-white font-medium">#</th>
                  <th className="px-6 py-4 text-white font-medium text-left">
                    Video Title
                  </th>
                  <th className="px-6 py-4 text-white font-medium">Views</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {videos.map((video, index) => (
                  <tr
                    key={video.id}
                    className="hover:bg-gray-50 transition-colors duration-150"
                  >
                    <td className="px-6 py-4 text-center text-gray-600">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4">
                      <a
                        href={`https://www.youtube.com/watch?v=${video.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 font-medium hover:underline"
                      >
                        {video.snippet.title}
                      </a>
                    </td>
                    <td className="px-6 py-4 text-center text-gray-600">
                      {video.statistics?.viewCount
                        ? Number(video.statistics.viewCount).toLocaleString()
                        : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoSearch;
