import { GrBlog } from "react-icons/gr";
import { HiOutlineHashtag } from "react-icons/hi";
import { SiSpeedtest } from "react-icons/si";
import {
  MdOutlineContentPasteSearch,
  MdOutlineArtTrack,
  MdInsights,
  MdAutoGraph,
  MdImageSearch,
  MdOutlineVideoSettings,
  MdOutlineIntegrationInstructions,
} from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { PiStrategyBold ,PiRadioactiveBold} from "react-icons/pi";
import { LuWorkflow } from "react-icons/lu";
import {
  GrMoney,
  GrSchedule,
  GrDocumentPerformance,
  GrAnalytics,
} from "react-icons/gr";

import { BsPlugin, BsGraphUpArrow, BsStars } from "react-icons/bs";
import {
  TbSettingsAutomation,
  TbUsersGroup,
  TbLoadBalancer,
  TbSocial,
  TbBrandGoogleAnalytics,
} from "react-icons/tb";

import { CgPerformance } from "react-icons/cg";
import { VscGraphScatter } from "react-icons/vsc";
import { GoGraph } from "react-icons/go";
import { TfiAnnouncement } from "react-icons/tfi";
import { LiaUsersSolid } from "react-icons/lia";

export const WebsitePerformance = {
  heading: "Overview",
  subHeading:
    "Optimize your website with tools to check website performance, ensuring faster load times, improved usability, and SEO compliance. Monitor real-time performance metrics to address gaps and increase web traffic effectively. Achieve how to improve site traffic goals with comprehensive analysis",
  points: [
    {
      title: "Faster Load Times",
      icon: <IoTimeOutline />,
      description:
        "Ensure your website loads quickly, improving user experience and reducing bounce rates for better engagement.",
    },
    {
      title: "Improved User Experience",
      icon: <CgPerformance />,
      description:
        "Enhance usability by identifying and addressing performance issues, leading to higher customer satisfaction and retention",
    },
    {
      title: "Increased Web Traffic",
      icon: <SiSpeedtest />,
      description:
        "Attract more visitors by optimizing performance metrics that directly impact search rankings and visibility.",
    },
    {
      title: "SEO Compliance",
      icon: <TbLoadBalancer />,
      description:
        "Align your website with SEO best practices by resolving technical issues, ensuring higher rankings on search engine results pages.",
    },
    {
      title: "Real-Time Monitoring",
      icon: <MdOutlineArtTrack />,
      description:
        "Stay ahead of potential issues with continuous performance tracking, enabling quick fixes and uninterrupted operations.",
    },
    {
      title: "Actionable Insights",
      icon: <MdInsights />,
      description:
        "Leverage detailed performance analysis to make informed decisions that drive long-term improvements in traffic and conversions.",
    },
  ],
};

export const Keywords = {
  heading: "Overview",
  subHeading:
    "Discover high-traffic keywords tailored to your niche using advanced AI-powered keyword research tools. Boost your online presence with optimized keyword search volume, SEO keyword analysis, and actionable suggestions for how to increase website traffic. Unlock the potential of your content with the best keyword research tools available.",
  points: [
    {
      title: "Boost Website Traffic",
      icon: <SiSpeedtest />,
      description:
        "Identify high-traffic keywords optimized for your niche, enabling your content to rank higher and attract more visitors organically.",
    },
    {
      title: "Improve SEO Performance",
      icon: <GrDocumentPerformance />,
      description:
        "Conduct detailed SEO keyword analysis and uncover actionable insights to fine-tune your strategy for better search engine rankings.",
    },
    {
      title: "Enhance Content Visibility",
      icon: <MdOutlineContentPasteSearch />,
      description:
        "Optimize your content using advanced tools to align with high-volume search terms, increasing its reach and impact.",
    },
    {
      title: "Save Time with Automated Research",
      icon: <IoTimeOutline />,
      description:
        "Eliminate manual efforts with AI-driven tools that streamline keyword discovery, delivering faster and more accurate results.",
    },
    {
      title: "Stay Ahead of Competitors",
      icon: <FaUsers />,
      description:
        "Gain insights into competitor strategies and target untapped keywords to establish a stronger online presence.",
    },
    {
      title: "Tailored Strategies for Your Niche",
      icon: <PiStrategyBold />,
      description:
        "Access keywords specifically relevant to your industry, ensuring your content resonates with your audience and drives engagement effectively.",
    },
  ],
};

export const BlogAutomation = {
  heading: "Overview",
  subHeading:
    "Create engaging, SEO-optimized blogs with AI-powered tools and publish them automatically to maximize visibility. Elevate your content marketing strategy with high-quality blogs that answer how to bring traffic to your websiteorganically and effectively. Automate for a seamless workflow, saving time and effort.",
  points: [
    {
      title: "Increased Organic Traffic",
      icon: <SiSpeedtest />,
      description:
        "Generate SEO-optimized blogs tailored to high-ranking keywords, boosting your website's visibility and attracting more organic visitors.",
    },
    {
      title: "Time-Saving Automation",
      icon: <TbSettingsAutomation />,
      description:
        "Automate the creation and posting process, streamlining workflows and freeing up time for other critical tasks. ",
    },
    {
      title: "Improved Content Quality",
      icon: <MdAutoGraph />,
      description:
        "Produce high-quality, engaging blogs with AI tools that ensure your content is relevant, informative, and aligned with audience needs.",
    },
    {
      title: "Consistent Posting Schedule",
      icon: <GrSchedule />,
      description:
        "Maintain a regular blogging cadence with automated publishing, ensuring your audience stays engaged and informed.",
    },
    {
      title: "Cost-Effective Content Strategy",
      icon: <PiStrategyBold />,
      description:
        "Reduce dependency on manual content creation, saving resources while delivering professional-grade blogs.",
    },
    {
      title: "Enhanced Search Engine Rankings",
      icon: <MdImageSearch />,
      description:
        "Leverage AI insights to target relevant keywords, improving your content's SEO performance and helping your site rank higher.",
    },
  ],
};

export const SocialMedia = {
  heading: "Overview",
  subHeading:
    "Stay connected with your audience using automatic social media posting tools. Automate targeted and consistent posts to grow brand visibility and boost web traffic. Ideal for how to gain website traffic and keep your marketing efforts on autopilot.",
  points: [
    {
      title: "Enhanced Brand Visibility",
      icon: <VscGraphScatter />,
      description:
        "Consistently engage with your audience through automated and timely posts, ensuring your brand stays top-of-mind.",
    },
    {
      title: "Time Efficiency",
      icon: <GoGraph />,
      description:
        "Save hours of manual effort by automating social media updates, allowing you to focus on core business strategies. ",
    },
    {
      title: "Increased Web Traffic",
      icon: <SiSpeedtest />,
      description:
        "Drive more visitors to your website through targeted social media posts that align with your content marketing goals.",
    },
    {
      title: "Improved Audience Engagement",
      icon: <BsStars />,
      description:
        "Keep your followers engaged with relevant and frequent updates, boosting interactions and building a loyal community.",
    },
    {
      title: "Cost-Effective Marketing",
      icon: <TbLoadBalancer />,
      description:
        "Reduce the need for extensive social media management by leveraging automation tools for consistent outreach.",
    },
    {
      title: "Streamlined Multi-Channel Management",
      icon: <TfiAnnouncement />,
      description:
        "Easily manage and post across multiple social platforms simultaneously, ensuring a cohesive and efficient strategy.",
    },
  ],
};

export const VideoCreationContent = {
  heading: "Overview",
  subHeading:
    "Leverage AI-driven video generation tools to produce compelling videos tailored for your audience. Automate seamless posting to platforms, helping you generate website traffic fast and amplify engagement. Perfect for learning how to increase visitors on your website while enhancing visual content strategy.",
  points: [
    {
      title: "Boost Website Traffic Quickly",
      icon: <SiSpeedtest />,
      description:
        "Generate engaging videos tailored to your audience and share them instantly, driving immediate traffic to your website.",
    },
    {
      title: "Enhanced Audience Engagement",
      icon: <TbUsersGroup />,
      description:
        " Create visually compelling content that resonates with your audience, increasing interaction and retention.",
    },
    {
      title: "Time-Saving Automation",
      icon: <IoTimeOutline />,
      description:
        "Automate video creation and posting, eliminating manual tasks and streamlining your content delivery process.",
    },
    {
      title: "Improved Visual Content Strategy",
      icon: <PiStrategyBold />,
      description:
        "Use AI tools to produce professional-quality videos that enhance your brand’s storytelling and marketing efforts",
    },
    {
      title: "Consistent Multi-Platform Presence",
      icon: <PiRadioactiveBold />,
      description:
        "Maintain an active presence on multiple platforms effortlessly by automating video publishing.",
    },
    {
      title: "Higher Conversion Potential",
      icon: <GrAnalytics />,
      description:
        "Captivate your audience with dynamic videos, encouraging more clicks, shares, and actions that lead to conversions.",
    },
  ],
};

export const ApplicationIntegration = {
  heading: "Overview",
  subHeading:
    "Fritado empowers users with effortless connectivity through Zapier integration, enabling seamless interaction with over 7,000 applications. Automate workflows, streamline processes, and boost productivity by integrating Fritado with tools your business already uses. Whether managing social media, scheduling, email marketing, or analytics, Fritado's integration capabilities open limitless possibilities for efficiency and growth.",
  points: [
    {
      title: "Streamlined Workflows",
      icon: <LuWorkflow />,
      description:
        "Automate repetitive tasks by connecting Fritado to essential apps, saving time and reducing manual effort.",
    },
    {
      title: "Enhanced Productivity",
      icon: <BsGraphUpArrow />,
      description:
        "Focus on strategic activities while Fritado automates content posting, data sharing, and task management across platforms.",
    },
    {
      title: "Unlimited App Compatibility",
      icon: <BsPlugin />,
      description:
        "Integrate with 7,000+ applications, from CRMs and marketing tools to project management and analytics platforms, to meet your business needs.",
    },
    {
      title: "Improved Collaboration",
      icon: <LiaUsersSolid />,
      description:
        "Sync data seamlessly between tools, ensuring your team stays informed and aligned in real-time.",
    },
    {
      title: "Scalable Automation",
      icon: <TbSettingsAutomation />,
      description:
        "Adapt workflows to evolving business requirements by adding or modifying integrations without complexity",
    },
    {
      title: "Cost-Effective Operations",
      icon: <GrMoney />,
      description:
        " Maximize resource efficiency by consolidating processes and reducing dependency on multiple standalone systems.",
    },
  ],
};

export const HomePageServiceData = [
  {
    id: 1,
    title: "AI-Powered Keyword Research for Maximum Visibility",
    description:
      "Unlock high-ranking keywords tailored to your niche to drive targeted traffic and boost your online presence.",
    link: "/AI-powered-keyword-research-for-maximum-visibility",
    icon: <HiOutlineHashtag />,
  },
  {
    id: 2,
    title: "Effortless Video Creation and Instant Auto-Posting",
    description:
      "Transform ideas into captivating videos with AI and publish them seamlessly to your platforms.",
    link: "/effortless-video-creation-and-instant-auto-posting",
    icon: <MdOutlineVideoSettings />,
  },
  {
    id: 3,
    title: "Smart Social Media Automation for Brand Growth",
    description:
      "Keep your audience engaged with automated, consistent, and targeted posts across all social channels.",
    link: "/smart-social-media-automation-for-brand-growth",
    icon: <TbSocial />,
  },
  {
    id: 4,
    title: "AI-Driven Blog Creation and Hassle-Free Posting",
    description:
      "Generate SEO-friendly blog content effortlessly and publish it automatically for enhanced visibility and engagement.",
    link: "/AI-driven-blog-creation-and-hassle-free-posting",
    icon: <GrBlog />,
  },
  {
    id: 5,
    title: "Website Performance Testing and Monitoring",
    description:
      "Monitor, analyze, and optimize your website for speed, usability, and SEO success with actionable insights.",
    link: "/website-preformance-testing-and-monitoring",
    icon: <TbBrandGoogleAnalytics />,
  },
  {
    id: 6,
    title: "Seamless Integration with 7000+ Applications",
    description:
      "Easily and seamlessly integrate with over 2000 applications such as Hubspot, Zoho, and more for comprehensive connectivity.",
    link: "/seamless-integration",
    icon: <MdOutlineIntegrationInstructions />,
  },
];
