import { BiAnalyse } from "react-icons/bi";
import { GrDocumentPerformance } from "react-icons/gr";
import { MdMoreTime } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdOutlineAutoGraph } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaCode } from "react-icons/fa6";
import { MdPublishedWithChanges } from "react-icons/md";
import { PiStarFourBold } from "react-icons/pi";
import { BsJournalBookmark } from "react-icons/bs";

export const BenefitContent = [
    {
      id: 1,
      title: "Boost Organic Traffic with Targeted Keywords",
      description: "Fritado’s AI-driven keyword research helps you rank higher on search engines by identifying and utilizing the most relevant and effective keywords for your business.",
      link: "/",
      icon: <BiAnalyse  />,
    },
    {
      id: 2,
      title: "Improve Website Performance for Better SEO",
      description: "Ensure faster loading times and enhanced user experience with continuous website performance testing and monitoring, leading to higher search rankings.",
      link: "/",
      icon: <GrDocumentPerformance  />,
    },
    {
      id: 3,
      title: "Save Time with Automated Video Content Creation",
      description: "Quickly produce professional-quality videos and automatically publish them to platforms like YouTube, reducing manual effort and time spent on content marketing.",
      link: "/",
      icon: < MdMoreTime  />,
    },
    {
      id: 4,
      title: "Enhance Content Quality with AI-Powered Blogs",
      description: "Deliver engaging and SEO-friendly blog posts tailored to your audience, increasing your website’s authority and attracting more visitors.",
      link: "/",
      icon: <BsJournalBookmark />,
    },
    {
      id: 5,
      title: "Strengthen Brand Visibility Across Social Platforms",
      description: "Automate your social media postings to keep your audience engaged and ensure consistent brand representation across all channels.",
      link: "/",
      icon: <PiStarFourBold/>,
    },
    {
      id: 6,
      title: "Streamline Content Marketing Efforts",
      description: "Simplify content creation and distribution processes with Fritado’s unified platform, saving resources while delivering high-impact marketing materials.",
      link: "/",
      icon: <MdPublishedWithChanges />,
    },
    {
      id: 7,
      title: "Maximize Engagement with SEO-Optimized Content",
      description: "Create blogs and videos tailored for search engines, ensuring higher visibility and better engagement with your target audience..",
      link: "/",
      icon: <FaCode />,
    },
    {
      id: 8,
      title: "Achieve Consistency in Marketing Campaigns",
      description: "Maintain a steady flow of high-quality content across your website, blog, and social media to keep your audience connected and engaged.",
      link: "/",
      icon: <TfiAnnouncement />,
    },
    {
      id: 9,
      title: "Gain Competitive Insights with Performance Monitoring",
      description: "Stay ahead of competitors by tracking your website’s performance metrics and implementing improvements based on real-time data.",
      link: "/",
      icon: <MdOutlineAutoGraph />,
    },
    {
      id: 10,
      title: "Increase ROI with Automation and AI",
      description: "Reduce manual workload and costs while achieving superior results through Fritado’s AI-powered tools for content creation and distribution.",
      link: "/",
      icon: <BsGraphUpArrow />,
    },

  ];