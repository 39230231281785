import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ScrollUp from "./ScrollUp";

const FooterFour = () => {
  const currentYear = new Date().getFullYear();
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <>
      <footer className="footer-area bg-cover full-width-footer  pd-top-100">
        <div className="footer-subscribe"></div>
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="">
                <img
                  src={"/assets/img/logo-white.png"}
                  style={{ width: "180px" }}
                  alt="Logo"
                />
              </div>
              <div className="widget widget-recent-post">
                <div className="widget widget_contact">
                  <p className="text-white py-2">
                    In a digital age where visibility and reach are key to
                    business success, Fritado offers an AI-driven approach that
                    simplifies and enhances SEO processes, making it easier for
                    companies to grow online. With a comprehensive platform that
                    automates up to 90% of SEO tasks and a variety of
                    complementary services, Fritado not only empowers businesses
                    but also helps shape the future of digital marketing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li className="sky">
                    <Link to="/" onClick={handleLinkClick}>
                      <span className="li-icon">
                        <FaChevronRight /> Home
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/about" onClick={handleLinkClick}>
                      <span className="li-icon">
                        <FaChevronRight /> About
                      </span>
                    </Link>
                  </li>

                  <li className="sky">
                    <Link to="/pricing" onClick={handleLinkClick}>
                      <span className="li-icon">
                        <FaChevronRight /> Pricing
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link
                      to="/Research-and-development"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight /> R&D Center
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/support" onClick={handleLinkClick}>
                      <span className="li-icon">
                        <FaChevronRight /> Contact Us
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5 ">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Solutions</h4>
                <ul>
                  <li className="sky">
                    <Link
                      to="/AI-powered-keyword-research-for-maximum-visibility"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight /> Keyword Research
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link
                      to="/effortless-video-creation-and-instant-auto-posting"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight /> Video Creation
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link
                      to="/smart-social-media-automation-for-brand-growth"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight /> Social Media Automation
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link
                      to="/AI-driven-blog-creation-and-hassle-free-posting"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight />
                        AI-Driven Blog Creation
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/seamless-integration" onClick={handleLinkClick}>
                      <span className="li-icon">
                        <FaChevronRight /> Seamless Integration
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link
                      to="/website-preformance-testing-and-monitoring"
                      onClick={handleLinkClick}
                    >
                      <span className="li-icon">
                        <FaChevronRight />
                        Website Performance Audit
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Others</h4>
                <ul>
                  <li className="sky">
                    <Link to="/terms-and-condition">
                      <span className="li-icon">
                        {" "}
                        <FaChevronRight /> Terms & Conditions
                      </span>
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/privacy-policy">
                      <span className="li-icon">
                        {" "}
                        <FaChevronRight /> Privacy Policy
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mb-3 widget_contact">
                <ul className="social-media" style={{ marginTop: "6rem" }}>
                  <li className="me-2">
                    <a href="https://www.facebook.com/fritado" className="sky">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li className="me-2">
                    <a href="https://www.instagram.com/" className="sky">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/fritado-com/?viewAsMember=true"
                      className="sky"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ScrollUp />
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md- d-flex justify-content-center">
                <p>
                  © {currentYear}{" "}
                  <Link to="https://fritado.com"> Fritado Technologies. </Link>{" "}
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterFour;
