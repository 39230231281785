export const planData = [
  {
    id: "1",
    plan: "Free Forever",
    planDesc:
      "Designed for small and medium enterprises to automate SEO services efficiently",
    amountCurrency: "$",
    amount: "0",
    amounttime: "/mo",
    planpara: "Free but worth it",
   
    planPoints: [
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: "5 Blogs",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "Unlimited Recommendations",
      },
      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "4 Posts",
      },
      {
        title: " Seamless Integration with 7000+ Applications",
        description: "Unlimited Potential",
      },
      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },
      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
    ],
  },
  {
    id: "2",
    plan: "Standard",
    planDesc:
      "Designed for small and medium enterprises to automate SEO services efficiently",
    amountCurrency: "$",
    amount: "49.99",
    amounttime: "/mo",
    planpara: "Billed monthly",
  
    planPoints: [
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: " 15 Blogs Monthly",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "Unlimited Recommendations",
      },
      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "15+ Posts Monthly",
      },
      {
        title: " Seamless Integration with 7000+ Applications",
        description: "Unlimited Potential",
      },
      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },
      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
    ],
  },
  {
    id: "3",
    plan: "Premium",
    planDesc:
      "Intended for established businesses and agencies",
    amountCurrency: "$",
    amount: "99.99",
    amounttime: "/mo",
    planpara: "Billed monthly",
  
    planPoints: [
    
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: "30 Blogs Monthly",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "Unlimited Recommendations",
      },
      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "30+ Posts Monthly",
      },
      {
        title: " Seamless Integration with 7000+ Applications",
        description: "Unlimited Potential",
      },
      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },
      {
        title: "Effortless Video Creation and Instant Auto-Posting",
        description: " 30 Videos",
      },
      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
      
    ],
  },
  
];
