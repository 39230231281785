import React from "react";
import MenuHeader from "../Navigation/MenuHeader";

const EnquiryMenu = () => {
  const EnquirytabsName = [
    { id: "1", title: "Demo", path: "/admin/dashboard/demo-enquiry" },
    { id: "2", title: "Sales", path: "/admin/dashboard/sales-enquiry" },

  ];
  return( <div>
    <MenuHeader tabsName={EnquirytabsName} />
  </div>);
};

export default EnquiryMenu;
