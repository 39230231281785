import React, { useRef } from "react";
import { BenefitContent } from "../../../Data/BenefitContent";
import { FaArrowRight } from "react-icons/fa";

const Benefits = () => {
  const sliderRef = useRef(null);

 
  const scrollForward = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="bg-color py-8">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center text-center head-div">
          <h2 className="heading white-title">
            Unlock the True Potential of Your Business with Fritado
          </h2>
          <div className="title-para ">
            <p className="white-title">
              Experience smarter solutions, increased efficiency, and measurable
              growth with our innovative platform.
            </p>
          </div>
        </div>
        {/* Benefit Card */}
        <div className="slider-container" ref={sliderRef}>
          <div className="d-flex flex-row slider-wrapper">
            {BenefitContent.map((benefit, id) => {
              return (
                <div key={id} className="benefit-card">
                  <div className="benefit-card-icon">{benefit.icon}</div>

                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
