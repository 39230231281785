import { GrBlog } from "react-icons/gr";
import { HiOutlineHashtag } from "react-icons/hi";
import { MdOutlineVideoSettings } from "react-icons/md";
import { TbSocial } from "react-icons/tb";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { BsBook } from "react-icons/bs";
import { MdOutlineLiveHelp } from "react-icons/md";
import { PiPresentationChartBold } from "react-icons/pi";

export const solutions = [
  {
    name: " AI-Powered Keyword Research for Maximum Visibility",
    link: "/AI-powered-keyword-research-for-maximum-visibility",
    icon: <HiOutlineHashtag />,
  },
  {
    name: "Effortless Video Creation and Instant Auto-Posting",
    link: "/effortless-video-creation-and-instant-auto-posting",
    icon: <MdOutlineVideoSettings />,
  },
  {
    name: "Smart Social Media Automation for Brand Growth",
    link: "/smart-social-media-automation-for-brand-growth",
    icon: <TbSocial />,
  },
  {
    name: "AI-Driven Blog Creation and Hassle-Free Posting",
    link: "/AI-driven-blog-creation-and-hassle-free-posting",
    icon: <GrBlog />,
  },
  {
    name: " Seamless Integration with 7000+ Applications",
    link: "/seamless-integration",
    icon: <MdOutlineIntegrationInstructions />,
  },
  {
    name: "Website Performance Testing and Monitoring",
    link: "/website-preformance-testing-and-monitoring",
    icon: <TbBrandGoogleAnalytics />,
  },
];


export   const Resource = [
    {
      name: "Blog  ",
      link: "/blog",
      icon: <BsBook />,
      desc: "Access popular guides, tips & stories about  automation.",
    },
    {
      name: "Help ",
      link: "/help-center",
      icon: <MdOutlineLiveHelp />,
      desc: "Explore our resources and documentation.",
    },
    {
      name: "Request for demo ",
      link: "/demo",
      icon: <PiPresentationChartBold />,
      desc: " See Our Solution in Action",
    },
  ];
