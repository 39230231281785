import React, { useEffect, useState } from "react";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaCaretUp } from "react-icons/fa";
import { solutions, Resource } from "../../Data/NavbarContent";

const Header = () => {
  const [active, setActive] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [solutionsDropdown, setSolutionsDropdown] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const menuActive = () => {
    setActive(!active);
    setShowButtons(!showButtons);
  };

  const toggelDropDown = () => {
    setDropDown(!dropDown);
  };
  const toggelSolutionDropDown = () => {
    setSolutionsDropdown(!solutionsDropdown);
  };
  const handleMouseEnterSolutions = () => {
    setSolutionsDropdown(true);
    setDropDown(true);
  };

  const handleMouseLeaveSolutions = () => {
    setSolutionsDropdown(false);
    setDropDown(false);
  };
  const handleMouseEnterResource = () => {
    setDropDown(true);
  };

  const handleMouseLeaveResource = () => {
    setDropDown(false);
  };

  const changeBackground = () => {
    if (window.scrollY >= 720) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      {/* ==================== Navbar Two Start ====================*/}
      <nav
        className={
          navbar
            ? "navbar navbar-area navbar-expand-lg bg-white li-color "
            : "navbar navbar-area navbar-area-3 navbar-expand-lg li-color"
        }
      >
        <div className=" container nav-container  bg-white ">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo ml-20">
            <Link to="/">
              <img src="/assets/img/logo3.png" alt="img" />
            </Link>
          </div>

          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-center ps-lg-5 navbarFive ">
              <li
                className="menu-item-has-children"
                onMouseEnter={handleMouseEnterSolutions}
                onMouseLeave={handleMouseLeaveSolutions}
              >
                <Link to="#" className="dropdown-icon">
                  Solutions
                  {solutionsDropdown ? (
                    <span className="drop-arrow">
                      <MdOutlineKeyboardArrowUp size={20} />{" "}
                    </span>
                  ) : (
                    <span className="drop-arrow">
                      <MdOutlineKeyboardArrowDown size={20} />{" "}
                    </span>
                  )}
                </Link>

                <ul
                  className={
                    solutionsDropdown ? "sub-menu active" : "sub-menu "
                  }
                >
                  <div className="dropdown-heading">
                    {" "}
                    <h4 className="dropdown-heading">Our Solutions</h4>
                  </div>
                  {solutions.map((solution, index) => (
                    <div className="d-flex p-2">
                      <li key={index} className="p-2 sub-items">
                        <Link to={solution.link}>
                          <div className="d-flex gap-4 sub-menu-content-box">
                            <span className="sub-items-icon">
                              {solution.icon}
                            </span>
                            <span className="sub-item-title">
                              {solution.name}
                            </span>
                          </div>
                        </Link>
                      </li>
                    </div>
                  ))}
                </ul>
              </li>

              <li className="pr-4">
                <Link to="/pricing">Pricing</Link>
              </li>

              <li
                className="menu-item-has-children"
                onMouseEnter={handleMouseEnterResource}
                onMouseLeave={handleMouseLeaveResource}
              >
                <Link to="#" className="dropdown-icon">
                  Resources
                  {dropDown ? (
                    <span className="drop-arrow">
                      <MdOutlineKeyboardArrowUp size={20} />{" "}
                    </span>
                  ) : (
                    <span className="drop-arrow">
                      <MdOutlineKeyboardArrowDown size={20} />{" "}
                    </span>
                  )}
                </Link>

                <ul className={dropDown ? "sub-menu active" : "sub-menu "}>
                  <div className="dropdown-heading">
                    {" "}
                    <h4 className="dropdown-heading">Our Resources</h4>
                  </div>
                  {Resource.map((resource, index) => (
                    <div className="d-flex p-2">
                      <li key={index} className="p-2 sub-items">
                        <Link to={resource.link}>
                          <div className="d-flex sub-menu-content-box">
                            <span className="sub-items-icon">
                              {resource.icon}
                            </span>
                            <div className="d-flex flex-column">
                              <span className="sub-item-title">
                                {resource.name}{" "}
                              </span>
                              <span>{resource.desc}</span>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </div>
                  ))}
                </ul>
              </li>

              <li>
                <Link to="/support">Support</Link>
              </li>
            </ul>
          </div>
          <div
            className={`nav-right-part  ${showButtons ? "show " : ""}`}
            // className=" nav-right-part nav-right-part-desktop align-self-center  d-flex flex-row align-items-center gap-3"
          >
            <div className="btn-tab demo-tab">
              <span className="">
                <Link to="/demo">Book a demo </Link>
              </span>
            </div>

            <span className="btn-tab btn-base-color solution-register">
              <Link to="https://platform.fritado.com/" target="_" className="">
                Get started FREE
              </Link>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
