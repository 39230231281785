export const contentPoints = [
  {
    title: "Custom AI Solutions",
    description:
      "We specialize in developing bespoke AI solutions tailored to the unique needs of our clients. Our team of experts works closely with businesses to understand their challenges and objectives, designing AI systems that deliver measurable results. From predictive analytics to natural language processing, our custom AI solutions are designed to enhance efficiency and drive growth.",
  },
  {
    title: "Real-Time Data Processing",
    description:
      "In today’s fast-paced world, real-time data processing is crucial for making informed decisions. Fritado R&D Center excels in developing systems that can process vast amounts of data in real time, providing actionable insights that help businesses stay agile and responsive. Our solutions enable clients to make data-driven decisions quickly and accurately.",
  },
  {
    title: "Machine Learning and Deep Learning",
    description:
      "Our expertise in machine learning and deep learning allows us to create sophisticated models that can learn from data and improve over time. Whether it’s developing recommendation systems, image recognition software, or predictive maintenance solutions, we harness the power of machine learning to deliver innovative and effective solutions.",
  },
  {
    title: "AI Integration and Deployment",
    description:
      "Implementing AI solutions seamlessly into existing systems can be challenging. Our team ensures smooth integration and deployment, minimizing disruption and maximizing benefits. We provide end-to-end support, from initial consultation and development to deployment and maintenance, ensuring that our clients can fully leverage their AI investments.",
  },
];

export const researchDevFaq = [
  {
    title: "What makes Fritado unique compared to other platforms?",
    description:
      "Fritado combines advanced AI-powered tools with seamless automation to optimize your content creation, social media posting, and keyword research. Our platform saves you time, enhances your online visibility, and drives measurable results effortlessly.",
  },
  {
    title: "How can Fritado help improve my website’s performance?",
    description:
      "Fritado offers in-depth performance testing and monitoring tools that identify issues, optimize speed, and ensure SEO compliance. This results in better user experiences and higher search engine rankings.",
  },
  {
    title: "Can Fritado increase my brand’s visibility online?",
    description:
      "Absolutely! With AI-driven content creation, automated social media posting, and advanced keyword research, Fritado boosts your brand's visibility across platforms and attracts more organic traffic to your website.",
  },
  {
    title: "How does Fritado save time for my business?",
    description:
      "Fritado automates repetitive tasks like blog creation, video posting, and social media updates, allowing you to focus on strategic growth while ensuring consistent and high-quality content delivery.",
  },
];
