import React, { useState, useEffect } from "react";
import EnquiryMenu from "./EnquiryMenu";
import axios from "axios";
import { DEMO_API_ROUTES } from "../../service/APIURL/Api";

const SalesEnquiry = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSalesData = async () => {
    const url = `${DEMO_API_ROUTES.FETCH_ENQUIRY}`;
    try {
      const salesFormsData = await axios.get(url);

      const response = salesFormsData.data;
      const salesResponse = response.filter((sale) => sale.companyName === "");

      setSalesData(salesResponse);
    } catch (error) {
      console.error("Error fetching demo forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSalesData();
  }, []);
  return (
    <div className="p-4">
      <div className="d-sm-flex d-flex flex-row heading-container">
        <h1 className="widget-title my-3">Sales Enquiries Management</h1>
      </div>
      <EnquiryMenu />

      <div className="table-container">
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th>Priority</th>
              <th>Name</th>
              <th>Email</th>
              <th>Country Code</th>
              <th>Phone No</th>
              <th>Message</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {salesData.length > 0 ? (
              salesData.map((form, index) => (
                <tr key={form._id}>
                  <td>{index + 1}</td>
                  <td>{`${form.firstname} ${form.lastname}` || "No Name"}</td>
                  <td>{form.email || "No Email"}</td>

                  <td>{form.countryCode || "No Country Code"}</td>
                  <td>{form.phoneNo || "No Phone No"}</td>
                  <td>{form.message}</td>
                  <td>{new Date(form.createdAt).toLocaleDateString() || "NO Date"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No Sales enquiries available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesEnquiry;
