import React from 'react'
import AuditPage from "../../components/PageSpeedAudits/AuditPage";
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import Keyword from '../../components/KeywordComp/Keyword';


const KeywordResearch = () => {
  return (
    <>
    <Header />
    <Breadcrumb
      title={"AI-Powered Keyword Research for Maximum Visibility"}
      para={
        "Discover top keywords and boost your search rankings."
      }
    />
    {/* <AuditPage /> */}
    <Keyword />
    <WebsiteAuditBanner />
    <Footer />
  </>
  )
}

export default KeywordResearch
