import React from "react";
import HomeBanner from "../components/HomeComponents/HomeBanner";
import Footer from "../components/commonComp/Footer";
import Navbar from "../components/HomeComponents/Navbar";
import BestServices from "../components/HomeComponents/BestServices";
import Clients from "../components/HomeComponents/Clients";
import Partner from "../components/HomeComponents/Partner";
import ResearchDevFAQ from "../components/ResearchDevelopment/ResearchDevFAQ";
import Testimonial from "../components/HomeComponents/Testimonial";
import LatestBlog from "../components/HomeComponents/LatestBlog";
import Benefits from "../components/HomeComponents/BenefitsComp/Benefits";


const HomeFive = () => {
  return (
    <>
      <Navbar />
      
        {/* Banner Five */}
        <HomeBanner />

       
        {/* <Partner /> */}

        {/* Service Area Five */}
        <BestServices />

        <Benefits />
        {/* FAQ */}
        <section>
          <div className="container ">
            <div className="d-flex flex-column justify-content-center align-items-center text-center head-div">
              <h2 className="heading">Why Choose Fritado AI?</h2>
              <div className="title-para">
                <p>
                  Discover the unmatched advantages that drive growth,
                  efficiency, and success for your business.
                </p>
              </div>
            </div>
            <div>
              <ResearchDevFAQ />
            </div>
          </div>
        </section>

        <Testimonial />
        {/* clients */}
        <LatestBlog />

        {/* <Clients /> */}
     
      {/* Footer Four */}
      <Footer />
    </>
  );
};

export default HomeFive;
