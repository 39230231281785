import React from 'react'
import VideoSearch from '../../components/VideoCreationComp/VideoSearch';
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import VideoCreation from "../../components/VideoCreationComp/VideoCreation"

const Video = () => {
  return (
    <>
    <Header />
    <Breadcrumb
      title={"Effortless Video Creation and Instant Auto-Posting"}
      para={
        "Create and publish videos effortlessly."
      }
    />
     <VideoSearch />
    <VideoCreation />
    <WebsiteAuditBanner />
    <Footer />
  </>
  )
}

export default Video
