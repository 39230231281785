import React, { useState, useEffect } from "react";
import ContactUsForm from "../ContactUs/ContactUsForm";
import { FaTimes } from "react-icons/fa";
import MessageModal from "./MessageModal";

const ContactUsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const showMessageModal = (type, message) => {
    setMessageModal({ type, message });
    document.body.classList.add("no-scroll");
  };

  const closeMessageModal = () => {
    setMessageModal(null);
    document.body.classList.remove("no-scroll");
  };

  return (
    <section className="head-div px-4">
      <div
        className="container p-4 bg-white"
        style={{ maxWidth: "655px", borderRadius: "8px" , boxShadow: '0px 4px 20px rgba(19, 16, 34, 0.06)'}}
      >
        <div className="d-flex flex-column justify-content-center align-items-center text-center title-content">
          <h4>Enterprise</h4>
          <div>
            <h2 className="heading">
              Looking for personalised solutions to elevate your business?
            </h2>
            <div className="title-para mb-2">
              <p>We’ve got you covered</p>
            </div>
          </div>
          <span
            className="btn-tab btn-base-color cursor-pointer"
            onClick={openModal}
          >
            Talk to Sales
          </span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div
            className="modal-content"
            style={{ maxWidth: "750px", height: "auto", overflowY: "hidden" }}
          >
            <span className="modal-close" onClick={closeModal}>
              <FaTimes size={24} />
            </span>
            <ContactUsForm closeModal={closeModal} showMessageModal={showMessageModal} />
          </div>
        </div>
      )}

      {messageModal && (
        <MessageModal
          type={messageModal.type}
          message={messageModal.message}
          onClose={closeMessageModal}
        />
      )}
    </section>
  );
};

export default ContactUsModal;
