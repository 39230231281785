import React from "react";
import { BlogAutomation } from "../../Data/ServiceContent";

const BlogCreation = () => {
  return (
    <div className="head-div">
        <div className="d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="px-4">
          <h2 className="heading">{BlogAutomation.heading}</h2>
          <div className="service-sub-heading title-para">
            <p className="text-center">{BlogAutomation.subHeading}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mx-auto">
          {/* cards */}
          <div className="service-cards-container">
            {BlogAutomation.points.map((point, index) => {
              return (
                <div key={index} className="service-card">
                <span className=" service-card-icon">{point.icon}</span>
                <h6 className="service-card-title">{point.title}</h6>
                <div className="service-card-desc">{point.description}</div>
              </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCreation;
