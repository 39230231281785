import React from "react";
import AuditPage from "../../components/PageSpeedAudits/AuditPage";
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import ConnectApplication from "../../components/IntegrationComp/ConnectApplication";

const Integration = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        title={"Seamless Integration with 7000+ Application"}
        para={
          "Connect with 7,000+ tools for workflow automation."
        }
      />
      <ConnectApplication />
      <WebsiteAuditBanner />
      <Footer />
    </>
  );
};

export default Integration;
