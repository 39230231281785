import React from 'react'
import AuditPage from "../../components/PageSpeedAudits/AuditPage";
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import WebsiteAuditing from '../../components/PageSpeedAudits/WebsiteAuditing';

const WebsitePerformance = () => {
  return (
    <>
    <Header />
    <Breadcrumb
      title={"Website Performance Testing and Monitoring"}
      para={
        "Optimize site performance for speed and SEO"
      }
    />
    <AuditPage />
    <WebsiteAuditing />
    <WebsiteAuditBanner />
    <Footer />
  </>
  )
}

export default WebsitePerformance;
