import React from "react";
import AuditPage from "../../components/PageSpeedAudits/AuditPage";
import Footer from "../../components/commonComp/Footer";
import Header from "../../components/commonComp/Header";
import Breadcrumb from "../../components/commonComp/Breadcrumb";
import WebsiteAuditBanner from "../../components/PageSpeedAudits/WebsiteAuditBanner";
import SocialMediaPosting from "../../components/SocialMediaComp/SocailMediaPosting";

const SocialPosting = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        title={"Smart Social Media Automation for Brand Growth"}
        para={
          "Automate posts to grow your brand."
        }
      />

      <SocialMediaPosting />
      <WebsiteAuditBanner />
      <Footer />
    </>
  );
};

export default SocialPosting;
