import React from "react";
import { Link } from "react-router-dom";
import Img4 from "../Assets/HomeAssets/Img4.png";
import { HomePageServiceData } from "../../Data/ServiceContent";

const ServiceAreaFive = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className="service-area  bg-relative head-div">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="d-flex flex-column justify-content-center align-items-center text-center head-div">
                <h2 className="heading">Empower Your Business with Our Smart Features</h2>
                <div className="title-para">
                  <p>
                  Explore powerful tools designed to boost performance, enhance visibility, and streamline your workflow.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row custom-no-gutter">
            <div className="col-lg-4 col-md-6">
              {HomePageServiceData.slice(0, 3).map((service) => (
                <div
                  key={service.id}
                  className="single-service-inner-3 single-service-inner-3-left"
                >
                  <div className="thumb">
                    <div className="thumb-inner">
                     
                      <span className="inner-image"> {service.icon}</span>
                    </div>
                  </div>
                  <div className="details">
                    <h5 className="mb-3 service-title">
                      <Link to={service.link} onClick={handleLinkClick}>
                        {service.title}
                      </Link>
                    </h5>
                    <p className="mb-0">{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none">
              <div className="service-thumb service-middle-section align-self-end">
                <img src={Img4} alt="servive-img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              {HomePageServiceData.slice(3).map((service) => (
                <div
                  key={service.id}
                  className="single-service-inner-3 single-service-inner-3-right "
                >
                  <div className="thumb">
                    <div className="thumb-inner">
                    <span className="inner-image"> {service.icon}</span>
                    </div>
                  </div>
                  <div className="details">
                    <h5 className="mb-3 service-title">
                      <Link to={service.link} onClick={handleLinkClick}>
                        {service.title}
                      </Link>
                    </h5>
                    <p className="mb-0">{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ServiceAreaFive;
