import React, { useState, useEffect } from "react";
import { BiMobile } from "react-icons/bi";
import PageSpeedCardMobile from "./PageSpeedCardMobile";
import PageSpeedCardDesktop from "./PageSpeedCardDesktop";
import { LuLaptop2 } from "react-icons/lu";
import { fetchPageSpeedData } from "../../service/pagespeed";
import Spinner from "../commonComp/Spinner";
import { useLocation } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";

const AuditPage = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("mobile");
  const [urlInput, setUrlInput] = useState(location.state?.urlInput || "");
  const [mobileData, setMobileData] = useState(null);
  const [desktopData, setDesktopData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const PageSpeedApiKey = "AIzaSyCHCEQO7ge4Rs6ABVtlcOWiejNFp5T9LWI";

  useEffect(() => {
    if (urlInput) {
      handleSubmit();
    }
  }, [urlInput]);

  const handleOnChange = (e) => {
    const inputPrefix = e.target.value.trim(); // fritado.com
    setUrlInput(
      inputPrefix.startsWith("https://") ? inputPrefix.slice(8) : inputPrefix
    );
  };
  const handleSubmit = async (e) => {
    //e.preventDefault();
    setLoading(true);
    const modifiedUrlInput = `https://${urlInput}`;

    try {
      const mobileData = await fetchPageSpeedData(
        modifiedUrlInput,
        PageSpeedApiKey,
        "mobile"
      );

      console.log(mobileData, "mobileData ");
      const desktopData = await fetchPageSpeedData(
        modifiedUrlInput,
        PageSpeedApiKey,
        "desktop"
      );
      setMobileData(mobileData);
      setDesktopData(desktopData);
      setDataFetched(true);
      setError(null);
    } catch (error) {
      setError(error.message);
      setLoading(false);
      setDataFetched(false);
      console.error("Error fetching PageSpeed data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="flex">
        <form
          onSubmit={handleSubmit}
          className="position-relative search-box-home flex flex-row justify-content-center items-center mx-auto container my-3"
        >
          <input
            type="text"
            name="urlInput"
            value={urlInput}
            onChange={handleOnChange}
            placeholder="example.com"
            className="px-3 py-3  col-lg-6 col-12 col-md-8 "
          />
          <span className="white-title audit-search" onClick={handleSubmit}>
            <IoIosSearch size={26} />
          </span>
         
        </form>
      </div>
      {loading && (
        <div className="flex flex-row gap-4 mx-auto justify-content-center items-center">
          <Spinner /> <p className="">Analyzing...</p>
        </div>
      )}
      {error && <p className="text-danger text-center">{error}</p>}
      {dataFetched && (
        <>
          <div className="flex items-center justify-content-center mx-auto gap-2">
            <h6
              onClick={() => setActiveTab("mobile")}
              className={`tab-button ${
                activeTab === "mobile" ? "active" : "inactive"
              }`}
            >
              <span>
                <BiMobile size={24} />
              </span>
              Mobile
            </h6>
            <h6
              onClick={() => setActiveTab("desktop")}
              className={`tab-button ${
                activeTab === "desktop" ? "active" : "inactive"
              }`}
            >
              <span>
                <LuLaptop2 size={24} />
              </span>
              Desktop
            </h6>
          </div>
          <div className="flex justify-content-center align-items-center  py-2">
            {activeTab === "mobile" && (
              <div className="centered-card">
                <PageSpeedCardMobile mobileData={mobileData} />
              </div>
            )}
            {activeTab === "desktop" && (
              <div className="centered-card">
                <PageSpeedCardDesktop desktopData={desktopData} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AuditPage;
