import React from "react";
import { VideoCreationContent } from "../../Data/ServiceContent";
import VideoSearch from "./VideoSearch";

const VideoCreation = () => {
  return (
    <div className="head-div">
        <div className="d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="px-4">
          <h2 className="heading">{VideoCreationContent.heading}</h2>
          <div className="service-sub-heading title-para">
            <p className="text-center">{VideoCreationContent.subHeading}</p>
          </div>
        </div>
       
        <div className="d-flex justify-content-center align-items-center mx-auto">
          {/* cards */}
          <div className="service-cards-container">
            {VideoCreationContent.points.map((point, index) => {
              return (
                <div key={index} className="service-card">
                  <span className=" service-card-icon">{point.icon}</span>
                  <h6 className="service-card-title">{point.title}</h6>
                  <div className="service-card-desc">{point.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCreation;
